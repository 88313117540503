.select-dropdown {
  .select-title {
    padding: 5px 12px;
    min-width: 134px;
    height: 35px;

    .fs-15 {
      color: white;
      font-weight: 700;
      letter-spacing: 0.4px;
      justify-content: space-between;
      width: 100%;

      svg {
        color: white;
      }
    }
  }

  .dropdown-menu {
    min-width: 178px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #C5C7CD;

    .dropDownSearch::placeholder{
      color: #C5C7CD;
    }

    li {
      padding: 0;
      margin-top: 0;

      &:last-child {
        button,
        a {
          border: 0;
        }
      }
    }

    button,
    a {
      color: white;
      font-size: 14px;
      letter-spacing: -0.333px;
      border-bottom: 0.5px solid #dfe0eb;
      display: flex;
      align-items: center;
      padding: 9px 10px;
      gap: 11px;
      width: 100%;

      svg {
        margin-right: 0;
      }
    }
  }
}
