.border-btn,
.delate-btn,
.cancel-btn {
    font-size: 14px;
    height: 33px;
    min-width: 102px;
    padding: 0 10px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-lg-6{
    .faded{
        color: "#c5c7cd";
    }
}

.bg-col-br{
    background-color: #6C5B51 !important;
}

.py-3{
    padding-bottom: 0rem !important;
}

.col-l-g{
    color: #00000061 !important;
}

.fault-search::placeholder{
    color: #c5c7cd !important;
}

.Checklists-dropdown {
    .dropdown-menu {
        padding: 16px 14px 35px;

    }

    .form-check {
        margin-top: 7px;
    }

    .dropdown-search {
        border-radius: 5px;
        border: 0.5px solid #F1EFEF;
        padding-left: 12px;
        background: #FFF;

        button {
            color: #C5C7CD;
            padding-right: 7px;
        }

        input {
            border: 0;
            outline: none;
            color: #C5C7CD;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: -0.333px;
            height: 40px;
        }
    }
}

.cancel-btn {
    background: #6C5B51;
}

.delate-btn {
    background: #D57D2A;
}

.border-btn {
    color: #6C5B51;
    border: 1px solid #6C5B51
}

.medium-modal {
    .modal-dialog {
        max-width: 591px;
    }
}

.example-custom-input {
    svg {
        color: rgba(88, 69, 57, 0.5);
    }
}

.fault-btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    row-gap: 20px;

    button {
        background: #F1EFEF;
        color: #000000;
        font-size: 15px;
        border-radius: 5px;
        height: 40px;
        padding: 0 15px;

        &.active,
        &.selected {
            background: #D57D2A;
            color: #FFFFFF;
        }
    }
}

.describe-input {
    width: 100%;
    background: #F1EFEF;
    border: 0;
    resize: none;
    outline: none;
    box-shadow: none;
    padding: 15px;
    height: 100px !important;
    border-radius: 5px;
}

.request-details .details-forms-one {
    margin-top: 0 !important;
    .form-check-input {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        padding: 0;
        background-color: #fff;
        border: 2px solid #C5C7CD;
        cursor: pointer;

        &[type="radio"]:checked {
            background-color: #D57D2A;
            border-color: #D57D2A;
        }


    }

}

.request-details {
    .attach-button {
        background: #F1EFEF;
        padding: 10px 15px;
        border-radius: 5px;
        margin-right: 30px;
    }
}

textarea {
    resize: none;
    outline: none;
    box-shadow: none;
}

.request-tab-content {
    table.table {
        td {
            padding: 20px 14px;
        }
    }
}


.request_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    row-gap: 18px;
    margin: 24px 0;

    @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575.98px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .request_item {

        &:nth-child(4) {
            grid-column: 1/-1;
        }
    }
}


.requestParts {
    .request_table {

        th,
        td {
            padding: 8px 0;
        }
    }
}


.check-icons-modal {
    .form-check-input {
        width: 20px;
        height: 20px;
        margin-top: 1px;
    }

    .form-check-inline {
        display: inline-flex;
        align-items: center;
        gap: 10px;
    }

    .form-check-input,
    .form-check-label {
        cursor: pointer;
    }
}


.pad-overflow {
    @media (max-width: 767.98px) {

        overflow: scroll;
    }
}

.quotes-pad {
    border-top: 8px solid #D57D2A;
    border-bottom: 8px solid #D57D2A;
    background: #F9F9F9;
    padding: 48px 40px;
    margin-top: 15px;
    min-width: 600px;
    height: 850px;
    display: grid;

    hr {
        margin: 24px 0;
    }

    .table {
        :not(caption)>*>* {
            background: none;
            padding: 6px 0;
            text-align: right;
            font-size: 10px;
            color: #000;

            &:first-child {
                text-align: left;
            }
        }

        th {
            font-size: 12px;
        }
    }
}

.quotes-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    font-size: 11px;
    color: #000;
    border-top: 1px solid #CFCFCF;
    padding-top: 10px;
    margin-top: auto;
}

.nots-pard {
    display: flex;
    justify-content: space-between;

    ul {
        display: grid;
        gap: 6px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 35px;

            span {
                font-size: 11px;
                color: #000;
            }
        }
    }
}

.quotes-head {
    svg {
        width: 15px;
        height: 15px;
        margin-left: 2px;
    }

    .fs-15 {
        @media (max-width: 575.98px) {
            font-size: 13px;
        }
    }
}

.horizonScroll {
    .request_table {
        overflow-y: scroll;
        height: 55vh;
        margin-right: 10px;
        padding-right: 10px;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #D9D9D9;
        }
    }
}
.quotes_visible {
    .quotes-download {
        display: hide;
    }
}

.quotes_hidden {
    .request_table {
        display: hide;
    }
}

@media print {
    .quotes-pad {
      height: 100vh;
    }
  }