.add-pm-area {
    border-radius: 8px;
    background: #FFF;
    margin: 21px 0;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 30px;
    padding: 35px 60px 200px 35px;

    @media (max-width: 991.98px) {
        grid-template-columns: 1fr;
        padding: 20px 20px 100px;
    }
}

.fadeColor{
    color: #00000061
}

.newHR{
    margin-top: 15px !important;
}

.add-pm-sidebar {}

.add-pm-content {
    .number-select {
        background-image: url("../../Assets/Images/upDown.svg");
        background-size: 21px 19px;
    }
}

.form-select:focus,
.form-check-input:focus {
    box-shadow: none;
}

.form-switch {
    input {
        width: 30px;
        height: 17.199px;
    }
}

.select-box {
    label {
        margin-bottom: 18px;
    }

    select {
        color: #000;
        border-radius: 5px;
        border: 0;
        width: 100%;
        outline: none;
        height: 41px;
        padding: 0 15px;
        appearance: none;
        background-color: #F1EFEF;
        background-image: url('../../Assets/Images/downIcon.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 14px auto, 100%;
        box-shadow: none;

        &.gray-color {
            color: rgba(0, 0, 0, 0.29);
        }
    }

}

.recurrence-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
    align-items: center;

    .namath-select {
        grid-column: 2/-1;
    }

}

.round-check {
    .form-check-input {
        width: 20px;
        height: 20px;
        border-radius: 100px;
        transform: translateY(-2px);
    }
}

.recurrence-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-top: 54px;

    @media (max-width: 575.98px) {
        grid-template-columns: 1fr;
    }

    .check-groupe {
        display: grid;
        gap: 30px;

        @media (max-width: 575.98px) {
            gap: 20px;
            margin-bottom: 10px;
        }
    }

    .form-check-input:checked+.form-check-label {
        color: rgba(213, 125, 42, 0.87);
    }

    .form-check {
        color: #C5C7CD;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.333px;
    }

    .pm-calender {
        margin-top: 15px;

        .example-custom-input {
            border-radius: 5px;
            background: #F1EFEF;
            width: 200px;
            height: 41px;
            display: flex;
            align-items: center;
            gap: 44px;
            justify-content: center;
            padding: 17px;
        }
    }
}

.Checklists-dropdown {
    .dropdown-menu {
        padding: 16px 14px 35px;

    }

    .form-check {
        margin-top: 7px;
    }

    .dropdown-search {
        border-radius: 5px;
        border: 0.5px solid #F1EFEF;
        padding-left: 12px;
        background: #FFF;

        button {
            color: #C5C7CD;
            padding-right: 7px;
        }

        input {
            border: 0;
            outline: none;
            color: #C5C7CD;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: -0.333px;
            height: 40px;
        }
    }
}

.high-btn {
    background: #F12B2C;
    border-radius: 100px;
    width: 79px;
    height: 21px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 7px;
}

.add-schedule-modal {
    .modal-content {
        border-radius: 8px;
        background: #FFF;
        padding: 30px 30px 65px;
        width: 650px;
        margin: 0 auto;

        @media (max-width: 575.98px) {
            padding: 00px;
        }
    }

    .add-schedule {
        max-width: 487px;
        margin: 0 auto;

        .row {
            row-gap: 23px;
        }

        label {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.333px;
            margin-bottom: 10px;
        }

        .form-check-label {
            margin-bottom: 0;
            font-size: 15px;
        }

        .form-check {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 20px;
        }

        .fs-16 {
            margin-bottom: 30px;
        }

        select {
            border-radius: 5px;
            width: 100%;
            height: 40px;
            border: 0;
            outline: none;
            color: #C5C7CD;
            padding: 0 15px;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: -0.333px;
            background-color: #F1EFEF;
            background-image: url("../../Assets/Images/downIcon.svg");
            background-repeat: no-repeat, repeat;
            background-position: right 0.7em top 50%, 0 0;
            background-size: 14px auto, 100%;
        }

        .next-btn {
            background: #D57D2ADE;
            width: 102px;
            height: 33px;
            border-radius: 8px;
            color: #FFF;
            font-size: 14px;
            letter-spacing: 0.2px;
            text-align: center;
            margin-top: 40px;
        }
    }

    .last-date {
        border-radius: 5px;
        background-color: #F1EFEF;
        width: 100%;
        height: 40px;
        border: 0;
        outline: none;
        color: #C5C7CD;
        padding: 0 10px;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.333px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 1;
        display: block;
        background: url("../../Assets/Images/date.svg") no-repeat;
        width: 20px;
        height: 20px;
        background-color: unset;

    }


}

.projected-con {
    .parts-box {
        .table {

            max-width: 620px;
        }
    }
}