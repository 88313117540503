.team-content {
    margin: 21px 0 21px;

    .table_fulls .table td:nth-child(2) {
        width: auto;
    }

    .team-table-nav {
        margin: 25px 22px 40px;
        @media (max-width: 991.98px){
            margin: 15px 14px 40px;
            gap: 20px;
        }
        .nav-link {
            color: #000;
        }
    }
    .pagination-center.mt-180{
        margin: 300px 0 20px;
        @media (max-width: 991.98px){
            margin: 100px 0 20px;
        }
    }

    .request_table{
        margin-top: 50px;
    }
}