.schedules-selection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 10px;

    .schedules-dropdown {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 575.98px) {
            flex-wrap: wrap;
        }

        .select-title {
            height: 30px;
        }

        .fs-14 {
            margin-bottom: 0;
        }
    }
}

.search__box {
    border: 0.5px solid #c5c7cd;
    display: flex;
    align-items: center;
    border-radius: 5px;

    input {
        border: 0;
        outline: none;
        width: 134px;
        height: 30px;
        color: rgba(88, 69, 57, 0.87);
        font-size: 14px;
        background: none;
    }

    button {
        color: #c4c4c4;
        padding: 3px 10px;
    }
}

.table_fulls {
    max-width: 100%;
    min-width: 100%;
    margin-top: 20px;

    .table {
        &:not(caption)>*>* {
            color: #000;
            letter-spacing: -0.333px;
        }

        th {
            color: #000;
            font-size: 15px;
            font-weight: 600;
        }

        td {
            font-size: 14px;
            padding-top: 20px;
            vertical-align: top !important;

            &:nth-child(2) {
                width: 74px;
            }
        }
    }

    .button-con {
        display: flex;
        gap: 22px;

        button {
            position: relative;
        }

        .view-number {
            color: #fff;
            font-size: 9px;
            font-weight: 500;
            letter-spacing: -0.333px;
            background: #d57d2a;
            width: 15px;
            height: 15px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -8px;
            top: -6px;
        }
    }
}

.pagination-center {
    justify-content: center;

    &.mt-180 {
        margin-top: 180px;
    }
}

.inspection-button {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin: 17px 0 32px;

    .add-schedule-btn {
        border-bottom: 1px solid rgba(213, 125, 42, 0.87);
    }

    button {
        color: rgba(213, 125, 42, 0.87);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.333px;
    }
}


.form-check-input {
    width: 20px;
    height: 20px;

    &:checked[type=radio] {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    }
}