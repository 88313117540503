.work-header {
    .fs-20 {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .work-time {
        border-radius: 100px;
        width: 79px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: #f12b2c;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}

.requestd-header {
    @media (max-width: 991.98px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 18px;

        .request-list {
            margin-right: 10px;
            justify-content: center;

            li {
                text-align: center;
            }
        }
    }

    @media (max-width: 620px) {
        .request-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 500px) {
        .request-list {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.order-request-area {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 11px;
    margin-top: 22px;

    .order-sidebar-content {
        grid-column: 1/5;
        border-radius: 8px;
        background: #fff;
        padding: 26px 35px 26px 26px;

        @media all and (max-width: 350px) {
            padding: 26px 20px 26px 20px;
        }

        @media (max-width: 991.98px) {
            grid-column: 1/-1;
        }

        .fs-13,
        .fs-14 {
            color: rgba(0, 0, 0, 0.38);
        }

        .text-ii {
            margin-top: 11px;
            display: grid;
            gap: 4px;
        }

        .text-dd {
            .fs-14 {
                text-align: right;
            }

            .dropdown {
                margin-top: 6px;
            }

            .drop-title {
                border-radius: 5px;
                border: 0.3px solid rgba(88, 69, 57, 0.5);
                background: #fff;
                color: #000;
                font-size: 14px;
                letter-spacing: -0.333px;
                max-width: 112px;
                min-width: 92px;
                padding-left: 5px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 21px;

                svg {
                    color: rgba(88, 69, 57, 0.87);
                }
            }

            .dropdown-menu {
                min-width: 92px;
                --bs-dropdown-link-active-bg: none;

                a {
                    color: #000;
                    font-size: 14px;
                    letter-spacing: -0.333px;
                }
            }
        }

        .order-sidebar-btn {
            align-items: center;
            justify-content: space-between;
            margin-top: 35px;

            button {
                border: 1px solid #584539de;
                color: rgba(88, 69, 57, 0.87);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
                width: 133px;
                height: 33px;
                border-radius: 5px;

                &:last-child {
                    background: #584539de;
                    color: #fff;
                }
            }
        }
    }

    .order-request {
        grid-column: 5/-1;
        border-radius: 8px;
        background: #fff;
        padding: 20px 16px 110px;

        @media (max-width: 991.98px) {
            grid-column: 1/-1;
        }
    }
}

.request-box {
    border-radius: 8px;
    border: 1px solid #f1efef !important;
    background: #fff;
    padding: 14px 25px 14px 15px;
    margin-top: 11px;

    .accordion-button {
        padding: 0;
        box-shadow: none;
        outline: none;

        &:after {
            background-image: url("../../Assets/Images/downIcon-2.svg");
            transform: rotate(0deg);
            width: 16px;
            height: 7px;
            background-size: 14px;
        }

        &:not(.collapsed) {
            background: none;
            box-shadow: none;

            &::after {
                transform: rotate(0deg);
            }
        }

    }

    &:first-child {
        margin-top: 0;
    }

    .request-box-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fs-13 {
            color: rgba(0, 0, 0, 0.38);
            text-transform: uppercase;
        }

        button {
            color: rgba(88, 69, 57, 0.87);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .request-box-body {
        margin-top: 12px;
        padding-left: 13px;

        .fs-14 {
            color: rgba(0, 0, 0, 0.38);
            letter-spacing: -0.333px;
            margin-bottom: 7px;
        }

        .checklists-list {
            margin-bottom: 20px;
        }

        .add-button {
            color: rgba(213, 125, 42, 0.87);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.333px;
            border-bottom: 1px solid #d57d2ade;
            margin-top: 35px;
        }
    }

    .attach-button {
        border-radius: 5px;
        background: #f1efef;
        color: #000;
        text-align: center;
        font-size: 15px;
        letter-spacing: -0.333px;
        padding: 8px 14px 8px 8px;
        cursor: pointer;
        margin-bottom: 30px;
    }

    .request_table {
        max-width: 630px;

        .table> :not(caption)>*>* {
            background: none;
            vertical-align: middle;
        }

        tbody tr {
            border-bottom: 1px solid #dfe0eb;
        }

        th {
            color: rgba(0, 0, 0, 0.38);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.333px;
        }

        td {
            color: #000;
            font-size: 15px;
            letter-spacing: -0.333px;

            @media (max-width: 767.98px) {
                min-width: 130px;
            }

            &:last-child {
                width: 20px;
            }
        }

        .button-con {
            display: flex;
            gap: 23px;

            button {
                color: #584539de;
            }
        }
    }

    .nav {
        gap: 40px;
        row-gap: 20px;
        margin: 25px 0 20px;

        .nav-link {
            background: none;
            padding: 0;
            color: #9fa2b4;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.333px;
            border-radius: 0;

            &.active {
                color: rgba(213, 125, 42, 0.87);
                border-bottom: 1px solid #d57d2ade;
            }
        }
    }
}

.edit-input {
    border: 1px solid transparent;
    outline: none !important;
    width: 100%;
}